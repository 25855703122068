<div class="container sign-out">
    <div class="row">
        <div class="col-12">
            <h1>Sign Out</h1>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="info">Are you sure you want to sign out?</div>        
        </div>
    </div>
    <div class="row">
        <div class="col-8">
            <button class="rectangle-button pink-button" (click)="SignOut(true)">Sign Out</button>
        </div>
        <div class="col-4">
            <button class="rectangle-button purple-button" (click)="SignOut(false)">Cancel</button>
        </div>
    </div>
</div>